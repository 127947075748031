#contact {
  color: black;
}

#contact .contactImageBg {
  color: white;
}

#contactContent {
  word-wrap: break-word;
  text-align: center;
}

#contactWrap {
  background-color: white;
}

#contact .contactImageBg #contactWrap {
  background-color: transparent;
}

#contactTitleWrap {
  margin-bottom: 0px;
}

#contactTitle {
  font-size: 45px;
  margin-bottom: 0px;
}

.contactTitleSub {
  font-size: 18px;
}

.contactTitleSubInline {
  display: inline-block;
}

#contactTitleSubWrap {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contactFormStyle {
  border: 2px solid rgb(44, 109, 169);
  border-radius: 0;
  padding: 12px;
}

#contact .contactImageBg .contactFormStyle {
  border-color: white;
}

.contactFormStyle::placeholder {
  color: black;
}

.contactFormStyle:hover,
.contactFormStyle:focus {
  background-color: rgba(44, 109, 169, 0.1);
}

#contact .contactImageBg .contactFormStyle:hover,
#contact .contactImageBg .contactFormStyle:focus {
  background-color: white;
}

#contactMessage {
  resize: none;
}

#contactMessage:hover {
  background-color: rgba(44, 109, 169, 0.1);
}

#contactButtons {
  margin: 10px 0 40px;
}

.contactButton {
  color: black;
  padding: 10px 200px;
  border: 2px solid rgb(44, 109, 169);
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
  display: inline-block;
}

#contact .contactImageBg .contactButton {
  border-color: white;
  color: white;
}

.contactButton:focus {
  color: black;
}

.contactButton:hover {
  color: white;
  background-color: rgb(44, 109, 169);
  text-decoration: none;
}

/* * * * * * * * */

#contact .customTitle {
  font-size: 40px;
  display: block;
}

#contact .customParagraph {
  font-size: 18px;
  display: block;
}

#contact .customLink {
  color: black;
  font-size: 18px;
  display: block;
  width: 100%;
}

#contact .customLink:hover {
  opacity: 0.6;
  text-decoration: none;
}

#contact .customIcon {
  font-size: 36px;
}

#contact .customTitle,
#contact .customParagraph,
#contact .customLink,
#contact .customIcon,
#contact .customButton,
#contact .customImage,
#contact .customEmbed {
  margin: 10px 0;
}

#contact #contactTitleWrap .customIcon,
#contact #contactTitleWrap .customButton,
#contact #contactTitleWrap .customImage {
  margin: 10px 0 0;
}

@media only screen and (max-width: 767px) {
  .contactButton {
      padding: 8px 120px;
  }
}

@media only screen and (max-width: 767px) {
  #contactTitle {
      font-size: 40px;
  }

  .contactTitleSub {
      font-size: 16px;
  }

  .contactButton {
      padding: 6px 42px;
  }

  #contact .customParagraph,
  #contact .customLink {
      font-size: 16px;
  }
}

#contactContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
