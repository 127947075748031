#headerContent {
  color: white;
  overflow: hidden;
  word-break: break-word;
}   

#headerTitle {
  font-size: 85px;
  text-shadow: 2px 2px 2px rgb(44,109,169);
}

.headerHrs {
  border: 2px solid white;
  width: 100%;
  margin: 0;
}

#headerSubtitleHrWrap,
#headerSubtitleWrap {
  text-align: right;
}

#headerSubtitle {
  font-style: italic;
}

@keyframes shootIn {

  0% {
      transform: scale(0); 
      -webkit-transform: scale(0); 
      opacity: 0
  }

  12% {
      transform: scale(0.02); 
      -webkit-transform: scale(0.02); 
      opacity: 1
  }

  24% {
      transform: scale(0.01); 
      -webkit-transform: scale(0.01); 
      opacity: 1
  }

  36% {
      transform: scale(0.06); 
      -webkit-transform: scale(0.06); 
      opacity: 1
  }

  54% {
      transform: scale(0.02); 
      -webkit-transform: scale(0.02); 
      opacity: 1
  }

  74% {
      transform: scale(0.25); 
      -webkit-transform: scale(0.25); 
      opacity: 1
  }

  82% {
      transform: scale(0.02); 
      -webkit-transform: scale(0.02); 
      opacity: 1
  }

  92% {
      transform: scale(0.56); 
      -webkit-transform: scale(0.56); 
      opacity: 1
  }

  96% {
      transform: scale(0.89); 
      -webkit-transform: scale(0.89); 
      opacity: 1
  }

  100% {
      transform: scale(1); 
      -webkit-transform: scale(1); 
      opacity: 1
  }
}

.animate {
  opacity: 0;
  animation: shootIn 1s ease-in-out forwards;
}

header .customTitle {
  font-size: 25px;
  text-shadow: 2px 2px 2px rgb(44,109,169);
  display: block;
}

header .customParagraph {
  font-size: 20px;
  display: block;
}

header .customLink {
  color: white;
  font-size: 20px;
  display: block;
  width: 100%;
}

header .customLink:hover {
  opacity: 0.5;
  text-decoration: none;
}

header .customIcon {
  font-size: 36px;
}

header .customTitle,
header .customParagraph,
header .customLink,
header .customIcon,
header .customButton,
header .customImage,
header .customEmbed {
  margin: 10px 0;
}

header #headerTitleWrap .customTitle,
header #headerTitleWrap .customParagraph,
header #headerTitleWrap .customLink,
header #headerTitleWrap .customIcon,
header #headerTitleWrap .customButton,
header #headerTitleWrap .customImage,
header #headerTitleWrap .customEmbed {
  margin: 0 0 20px;
}

header #headerTitleHrWrap .customTitle,
header #headerTitleHrWrap .customParagraph,
header #headerTitleHrWrap .customLink,
header #headerTitleHrWrap .customIcon,
header #headerTitleHrWrap .customEmbed {
  width: 100%;
  text-align: center;
  margin: 30px 0 0;
}

header #headerTitleHrWrap .customButtonContainer, 
header #headerTitleHrWrap .customImageContainer {
  width: 100%;
  text-align: center;
}

header #headerTitleHrWrap .customButton,
header #headerTitleHrWrap .customImage {
  margin: 30px auto 0;
}

header #iconContainer .customTitle,
header #iconContainer .customParagraph,
header #iconContainer .customLink,
header #iconContainer .customIcon,
header #iconContainer .customButton,
header #iconContainer .customImage,
header #iconContainer .customEmbed {
  margin: 0 0 10px;
}

header #headerSubtitleHrWrap .customTitle,
header #headerSubtitleHrWrap .customParagraph,
header #headerSubtitleHrWrap .customLink,
header #headerSubtitleHrWrap .customIcon,
header #headerSubtitleHrWrap .customButton,
header #headerSubtitleHrWrap .customImage,
header #headerSubtitleHrWrap .customEmbed {
  margin: 20px 0 0;
}

header #headerSubtitleWrap .customEmbed {
  margin: 20px 0 0;
}

@media only screen and (max-width : 767px) {
  
  #headerTitleWrap {
      text-align: center;
  }

  #headerTitle {
      font-size: 54px;
  }

  #headerSubtitleHrWrap,
  #headerSubtitleWrap {
      text-align: center;
  }

  #headerMedia {
      margin: 18px auto 0;
  }

  #iconContainer {
      padding-left: 0;
      margin: 4px 0px 4px 0px;
  }

  .headerIcon {
      padding: 8px;
  }

  header #headerTitleHrWrap .customTitle,
  header #headerTitleHrWrap .customParagraph,
  header #headerTitleHrWrap .customLink,
  header #headerTitleHrWrap .customIcon,
  header #headerTitleHrWrap .customEmbed,
  header #headerTitleHrWrap .customButton,
  header #headerTitleHrWrap .customImage {
      margin: 20px 0 10px;
  }

  header #iconContainer .customTitle,
  header #iconContainer .customParagraph,
  header #iconContainer .customLink,
  header #iconContainer .customIcon,
  header #iconContainer .customButton,
  header #iconContainer .customImage,
  header #iconContainer .customEmbed {
      margin: 0 15px 5px 0;
  }

  header #iconContainer .customImage {
      padding-right: 18px;
  }
}

#aboutParagraphWrap {
  margin: auto;
  text-align: center;
  width: 400px;
}

@media only screen and (max-width : 767px) {
  
  #headerTitle {
      font-size: 46px;
  }

  #headerSubtitle {
      font-size: 18px;
  }

  #aboutParagraphWrap {
    width: auto;
    margin: auto;
    max-width: 80%;
  }
}