/* Navbar.css */

.container {
  max-width: 92%;
}

#mainMenu {
  border: none;
  padding: 5px;
  word-break: break-word;
}

#menuTitle {
  font-size: 22px;
  color: white;
  text-transform: uppercase;
  line-height: normal;
}

.menuItemA {
  font-size: 14px;
  cursor: pointer;
  color: white; /* Ensure menu links are white */
}

#menuItems {
  margin-top: 10px;
}

#menuItems .menuItemA:hover {
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0);
}

#menuItems .menuItemA:focus {
  background-color: rgba(0, 0, 0, 0);
}

.menuButton {
  display: none;
  background: white;
  color: rgb(44, 109, 169);
}

.openMenuIcon {
  color: rgb(44, 109, 169); /* Adjust icon color */
}

/* Specific styles for menu items when visible or hidden */
.menuItemLi {
  display: flex; /* Align items correctly */
  text-align: center;
}

@media only screen and (max-width: 767px) {
  #navigationBar {
    margin: auto;
    margin-top: -60px;
    padding: 20px;
  }
  #menuButtons {
    background-color: #090909;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 5px rgba(196, 89, 47, 0.83);
    width: 160px;
    margin: auto;
    padding: 10px;
  }

  #menuItems {
    margin-top: 0px;
  }
}

/* Responsive styles */
@media only screen and (min-width: 768px) {
  #mainMenu {
      padding: 20px;
  }

  #mainMenu .container {
      width: 80%;
  }

  #menuButtons {
      margin-top: 10px;
      margin-left: 20px;
  }

  #mainMenu {
      text-align: center;
  }

  #menuTitle {
      margin-top: 4px;
  }

  #navigationBar {
      max-height: none;
  }

  #menuButtons .menuItemButton {
      padding: 5px 50px;
  }
}
