.team-members-container {
  display: flex;
  overflow: hidden;
  position: relative;
  padding: 0px 0px 30px 0px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 92%;
}

.team-members {
  display: flex;
  gap: 30px; /* Gap between columns and rows */
  transition: transform 0.3s ease; /* Smooth transition for scrolling */
}

.team-member {
  text-align: center;
  width: 260px;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.prev-button {
  margin-right: 30px;
}

.next-button {
  margin-left: 30px;
}

.scroll-button {
  background-color: #FFD700;
  color: #333;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 220px;
}

@media only screen and (min-width: 701px) {
  .scroll-button:hover {
    background-color: #333;
    color: #FFD700;
  }
}

.teamMemberImages {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}

#team {
  color: black;
}

#team .teamImageBg {
  color: white;
}

#teamContent {
  word-wrap: break-word;
  text-align: center;
}

#teamTitleWrap {
  margin-bottom: 40px;
}

#teamTitle {
  font-size: 45px;
  margin: 10px 0;
}

.teamMemberInfoWrap {
  box-shadow: 0 0 10px 4px rgb(255, 217, 102);
  color: white;
  padding: 15px 5px 0px 5px;
  border-radius: 5px;
  min-width: 250px;
  max-width: 250px;
  margin: 5px auto 0;
  height: 240px;
}

.teamMemberName {
  font-size: 24px;
  margin: 0;
}

.teamMemberHrWrap {
  margin-top: 5px;
  margin-bottom: 5px;
}

.teamMemberText {
  margin-bottom: 0;
  height: 98px;
  align-content: center;
}

/* Responsive Styles */
@media only screen and (max-width: 767px) {
  .teamTemplate {
    margin: 0;
    padding: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .team-members-container {
    max-width: 96%;
    height: 515px;
  }
  .prev-button {
    margin-left: -45px;
  }
  .next-button {
    margin-right: -45px;
  }
  .scroll-button {
    position: absolute;
    bottom: 0px;
    background-color: #FFD700;
    color: #333;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .team3 {
    display: initial;
    margin: 0;
  }

  .teamTemplate {
    margin: 30px 0;
    padding: 0;
  }

  .teamMemberInfoWrap {
    width: 100%;
  }
}